.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.5rem;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  // box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn:active:focus,
.btn.active:focus,
.btn.show:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled,
.btn.disabled {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: hsl(314, 59%, 58%);
  --bs-btn-border-color: #0B2911;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: hsl(314, 59%, 48%);
  --bs-btn-hover-border-color: hsl(314, 59%, 48%);
  --bs-btn-focus-shadow-rgb: 82, 175, 149;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: hsl(314, 59%, 38%);
  --bs-btn-active-border-color: hsl(314, 59%, 38%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0B2911;
  --bs-btn-disabled-border-color: #0B2911;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0B2911;
  --bs-btn-border-color: #0B2911;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: hsl(132, 58%, 10%);
  --bs-btn-hover-border-color: #294c49;
  --bs-btn-focus-shadow-rgb: 53, 86, 83;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: hsl(132, 58%, 10%);
  --bs-btn-active-border-color: #294c49;
}

.btn-outline-dark {
  --bs-btn-color: #0B2911;
  --bs-btn-border-color: #0B2911;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0B2911;
  --bs-btn-hover-border-color: #0B2911;
  --bs-btn-focus-shadow-rgb: 17, 56, 53;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0B2911;
  --bs-btn-active-border-color: #0B2911;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 82, 175, 149;
  text-decoration: underline;
}

.btn-link:focus {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-border-radius: 0.25rem;
}

.btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 1;
}

.btn-group {
  border-radius: 0.5rem;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(97, 206, 175, 0.25);
  opacity: 1;
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-primary {
  color: #fff;
}

.btn-primary:hover {
  color: #fff;
}

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-close {
  padding: 0;
  font-size: 1.3rem;
  line-height: 1;
}

.btn-group-contact .btn {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  --bs-btn-color: #fff;
  --bs-btn-bg: hsl(314, 59%, 58%);
  --bs-btn-border-color: hsl(314, 59%, 58%);;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: hsl(314, 59%, 48%);
  --bs-btn-hover-border-color: hsl(314, 59%, 48%);
  --bs-btn-focus-shadow-rgb: 83, 144, 130;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: hsl(314, 59%, 38%);
  --bs-btn-active-border-color: hsl(314, 59%, 38%);
}

.btn-group-contact .btn:not(:first-child) {
  margin-left: 1px !important;
}

.btn-group-contact .btn:hover {
  color: #fff;
}
