.login-modal-inner {
  .panel-modal-inner {
    border-radius: 28px !important;
    //border: 1px solid #81DC59 !important;
    background: #FFFFFF !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
    position: fixed;
    bottom: calc(100vh + 20px);
    left: 50%;
    z-index: 10002;
    padding: 40px 20px 20px;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 100vw;
    height: auto;
    overflow-y: visible;
    max-width: 717px;
    opacity: 1;

    @media (max-width: 768px) {
      padding: 30px;
      height: auto;
      max-width: 100vw !important;
      bottom: 0 !important;
      border-radius: 10px;
      transition: all 0.303s ease-in-out;
      transform: translate(-50%, 0) !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .background-image {
      position: absolute;

      .background-image-top {
        position: relative;
        top: -7rem;
        mix-blend-mode: multiply;
      }

      .background-image-bottom {
        mix-blend-mode: multiply;
        z-index: 10002 !important;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .close-icon-button {
      position: absolute;
      top: -1.563rem;
      right: -1.563rem;

      @media (max-width: 768px) {
        top: 0.625rem;
        right: 0.625rem;
      }
    }

  }

  .popup-content {
    .otp-input-container {
      position: relative;
      width: 60%;
      z-index: 10004 !important;
      height: 52px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      border: 1px solid rgba(255, 255, 255, 0.20);
      padding: 5px;
      color: #495057;
      background-color: rgba(221, 223, 225, 0.4);

      @media (max-width: 768px) {
        width: 100%;
      }

      input {
        color: rgba(255, 255, 255, 0.10);

        text-align: center;
        font-family: Tahoma, Arial, sans-serif;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 18px;
        color: #fff;
        background-color: transparent;
        border: none !important;
      }

      input:focus {
        color: #fff;
        background-color: transparent;
        outline: 0;
      }

      input:-webkit-autofill,
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px #00000000;
      }

      input::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.10)
      }

      input::-moz-placeholder {
        color: rgba(255, 255, 255, 0.10)
      }

      input:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.10)
      }

      input::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.10)
      }

      input::placeholder {
        color: rgba(255, 255, 255, 0.10)
      }

      button {
        border-radius: 5px;
        margin-left: 5px;
        color: #1A732D;
        background: #91F463;;
        text-align: center;
        font-family: Tahoma, Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;


        &:focus {
          outline: none !important;
        }

        &:hover {
          background: #81DC59 !important;
          color: #0B2911 !important;
        }

        &:active {
          background: #81DC59 !important;
          color: #0B2911 !important;
        }
      }
    }

    span {
      color: #D8D8D8;
      text-align: center;
      font-family: Tahoma, Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
    }

    h3 {
      color: #FFF;
      text-align: center;
      font-family: Tahoma, Arial, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 54px; /* 192.857% */
      margin-top: 1.5rem;
    }

    a {
      color: #91F463;
      text-align: center;
      font-family: Tahoma, Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 54px; /* 337.5% */
      text-decoration-line: underline;
    }
  }
}