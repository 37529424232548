.error-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;

  .error-image {
    width: 100%;
    max-width: 500px;
    margin-bottom: 2rem;
    padding: 3rem;

    @media (max-width: 768px) {
      padding: 0.5rem 5rem;
      margin-bottom: 0;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
}