$switchWidth: 55px;
$switchHeight: 25px;

.checkbox-notification {
  display: none !important;
}

.checkbox-notification + label {
  display: inline-block !important;
  position: relative !important;
  width: $switchWidth !important;
  height: $switchHeight !important;
  background: #fff !important;
  border-radius: 70px !important;
  padding: 0 !important;
  cursor: pointer !important;
  transition: all 500ms ease !important;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2) inset !important;

  &:before {
    content: 'Off' !important;
    width: 25px !important;
    height: 25px !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    border-radius: 100% !important;
    background: #97A3AD !important;
    text-align: center !important;
    line-height: 25px !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 8px !important;
    font-weight: normal !important;
    color: #fff !important;
    text-transform: uppercase !important;
    border: none !important;
    transition: left 500ms ease, color 500ms ease, transform 150ms ease !important;
  }

  &:after {
    content: '' !important;
  }

  &:active {
    &:before {
      transform: scale(0.95) !important;
    }
  }
}

.checkbox-notification:checked + label {
  &:before {
    content: 'On' !important;
    color: #fff !important;
    left: ($switchWidth - 25px) !important;
    border: none !important;
    background: #8EE559 !important;
  }
}