.logging-in-container {
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(255, 255, 255);
  padding: 20px;
  color: #1A732D;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;

  .logging-in-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .logging-in-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }
  }
}