.panel-page {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10001;
  background: #fff;
  padding: 40px 60px 40px 40px;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  transition: all 0.303s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .panel-page {
    transition: none;
  }
}
@media (min-width: 992px) {
  .panel-page {
    width: 50vw;
  }
}
.panel-page.active {
  -webkit-transform: translatex(0);
  transform: translatex(0);
}

.panel-profile {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10001;
  background: #fff;
  padding: 40px;
  width: 100vw;
  transition: all 0.303s ease-in-out;
}
@media (min-width: 768px) {
  .panel-profile {
    width: 300px;
  }
}
@media (prefers-reduced-motion: reduce) {
  .panel-profile {
    transition: none;
  }
}
.panel-profile.active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.panel-profile .nav li + li {
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-top: 1px solid rgba(17, 56, 53, 0.8);
}
.panel-profile .nav li.last {
  border-top-width: 2px;
}
.panel-profile .nav a {
  color: #113835;
  text-decoration: none;
}

.panel-modal-inner {
  position: fixed;
  bottom: calc(100vh + 20px);
  left: 50%;
  z-index: 10002;
  background: #fff;
  padding: 40px 20px 20px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  max-width: 600px;
  opacity: 1;
}
@media (min-width: 576px) {
  .panel-modal-inner {
    max-height: 90vh;
  }
}
@media (min-width: 768px) {
  .panel-modal-inner {
    padding: 30px;
    height: auto;
    border-radius: 10px;
    transition: all 0.303s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .panel-modal-inner {
    transition: none;
  }
}
.panel-modal-sm .panel-modal-inner {
  max-width: 450px;
}
.panel-modal.active .panel-modal-inner {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  bottom: 50%;
  opacity: 1;
}
.panel-modal.active .panel-overlay {
  width: 100vw;
  height: 100vh;
  opacity: 1;
}

.panel-overlay {
  top: 0;
  right: 0;
  content: '';
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9000;
  opacity: 0;
}
@media (min-width: 768px) {
  .panel-overlay {
    transition: opacity 0.303s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .panel-overlay {
    transition: none;
  }
}
