.privacy-container {

  h3 {
    color: #00A874;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 26.4px */

    @media (max-width: 768px) {
      font-size: 25px;
    }
  }

  p {
    color: #2C2C2C;
    leading-trim: both;
    text-edge: cap;
    font-family: Tahoma, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
}