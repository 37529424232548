.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
