.policy-table {
  font-family: inherit;
  font-size: 15px;
}

.policy-table table, .policy-table tr, .policy-table td, .policy-table th {
  border: 1px solid darkgrey !important;
  padding: 5px;
  background-color: gainsboro;
  border-collapse: collapse !important;
}