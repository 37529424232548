.telegram-button {
  height: 50px;
  border-radius: 5px;
  background-color: #0088cc;
  color: #fff;
  font-family: Arial, sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  margin-top: 5px;
  &:hover {
    background-color: #0077b3;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
}

.success {
	color: #91F463;
	text-align: center !important;
}

.successcontent
{
	text-align: center !important;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}
