.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}
.select2-container[dir='rtl']
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}
.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}
.select2-container
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--bootstrap-5 {
  display: block;
}
select + .select2-container--bootstrap-5 {
  z-index: 1;
}

.select2-container--bootstrap-5 *:focus {
  outline: 0;
}
.select2-container--bootstrap-5 .select2-selection {
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap-5 .select2-selection {
    transition: none;
  }
}
.select2-container--bootstrap-5.select2-container--focus .select2-selection,
.select2-container--bootstrap-5.select2-container--open .select2-selection {
  border-color: #b0e7d7;
  box-shadow: 0 0 0 0.25rem rgba(97, 206, 175, 0.25);
}
.select2-container--bootstrap-5.select2-container--open.select2-container--below
  .select2-selection {
  border-bottom: 0 solid transparent;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--bootstrap-5.select2-container--open.select2-container--above
  .select2-selection {
  border-top: 0 solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--bootstrap-5 .select2-search {
  width: 100%;
}
.select2-container--bootstrap-5 .select2-search--inline .select2-search__field {
  vertical-align: top;
}
.select2-container--bootstrap-5
  .select2-selection--single
  .select2-selection__clear,
.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__clear {
  position: absolute;
  top: 50%;
  right: 2.25rem;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0.25em 0.25em;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/0.75rem auto no-repeat;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.select2-container--bootstrap-5
  .select2-selection--single
  .select2-selection__clear:hover,
.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__clear:hover {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/0.75rem auto no-repeat;
}
.select2-container--bootstrap-5
  .select2-selection--single
  .select2-selection__clear
  > span,
.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__clear
  > span {
  display: none;
}

.select2-container--bootstrap-5 + .select2-container--bootstrap-5 {
  z-index: 1056;
}
.select2-container--bootstrap-5 .select2-dropdown {
  z-index: 1056;
  overflow: hidden;
  color: #212529;
  background-color: #fff;
  border-color: #b0e7d7;
  border-radius: 0.5rem;
}
.select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--below {
  border-top: 0 solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--above {
  border-bottom: 0 solid transparent;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-search {
  padding: 0.375rem 0.75rem;
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-search
  .select2-search__field {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap-5
    .select2-dropdown
    .select2-search
    .select2-search__field {
    transition: none;
  }
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-search
  .select2-search__field:focus {
  border-color: #b0e7d7;
  box-shadow: 0 0 0 0.25rem rgba(97, 206, 175, 0.25);
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options:not(.select2-results__options--nested) {
  max-height: 15rem;
  overflow-y: auto;
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option.select2-results__message {
  color: #6c757d;
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option.select2-results__option--highlighted {
  color: #000;
  background-color: #e9ecef;
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option.select2-results__option--selected,
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option[aria-selected='true']:not(
    .select2-results__option--highlighted
  ) {
  color: #000;
  background-color: hsl(314, 59%, 48%);
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option.select2-results__option--disabled {
  color: #6c757d;
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option[role='group'] {
  padding: 0;
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option[role='group']
  .select2-results__group {
  padding: 0.375rem 0.375rem;
  font-weight: 700;
  line-height: 1.5;
  color: #6c757d;
}
.select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option[role='group']
  .select2-results__options--nested
  .select2-results__option {
  padding: 0.375rem 0.75rem;
}

.select2-container--bootstrap-5 .select2-selection--single {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}
.select2-container--bootstrap-5
  .select2-selection--single
  .select2-selection__rendered {
  padding: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}
.select2-container--bootstrap-5
  .select2-selection--single
  .select2-selection__rendered
  .select2-selection__placeholder {
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
}
.select2-container--bootstrap-5
  .select2-selection--single
  .select2-selection__rendered
  .select2-selection__arrow {
  display: none;
}

.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__rendered {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 0;
  list-style: none;
}
.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.35em 0.65em;
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  color: #212529;
  cursor: auto;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
}
.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove {
  width: 0.75rem;
  height: 0.75rem;
  padding: 0.25em 0.25em;
  margin-right: 0.25rem;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/0.75rem auto no-repeat;
  border: 0;
}
.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove:hover {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/0.75rem auto no-repeat;
}
.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove
  > span {
  display: none;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-search {
  display: block;
  width: 100%;
  height: 1.5rem;
}
.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-search
  .select2-search__field {
  width: 100%;
  height: 1.5rem;
  margin-top: 0;
  margin-left: 0;
  font-family: inherit;
  line-height: 1.5;
  background-color: transparent;
}
.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__clear {
  right: 0.75rem;
}

.select2-container--bootstrap-5.select2-container--disabled .select2-selection,
.select2-container--bootstrap-5.select2-container--disabled.select2-container--focus
  .select2-selection {
  color: #6c757d;
  cursor: not-allowed;
  background-color: #e9ecef;
  border-color: #ced4da;
  box-shadow: none;
}
.select2-container--bootstrap-5.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__clear,
.select2-container--bootstrap-5.select2-container--disabled.select2-container--focus
  .select2-selection--multiple
  .select2-selection__clear {
  display: none;
}
.select2-container--bootstrap-5.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--bootstrap-5.select2-container--disabled.select2-container--focus
  .select2-selection--multiple
  .select2-selection__choice {
  cursor: not-allowed;
}
.select2-container--bootstrap-5.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove,
.select2-container--bootstrap-5.select2-container--disabled.select2-container--focus
  .select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove {
  display: none;
}
.select2-container--bootstrap-5.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__rendered:not(:empty),
.select2-container--bootstrap-5.select2-container--disabled.select2-container--focus
  .select2-selection--multiple
  .select2-selection__rendered:not(:empty) {
  padding-bottom: 0;
}
.select2-container--bootstrap-5.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__rendered:not(:empty)
  + .select2-search,
.select2-container--bootstrap-5.select2-container--disabled.select2-container--focus
  .select2-selection--multiple
  .select2-selection__rendered:not(:empty)
  + .select2-search {
  display: none;
}

.select2-container--bootstrap-5 .select2--small.select2-selection {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.select2-container--bootstrap-5
  .select2--small.select2-selection--single
  .select2-selection__clear,
.select2-container--bootstrap-5
  .select2--small.select2-selection--multiple
  .select2-selection__clear {
  width: 0.5rem;
  height: 0.5rem;
  padding: 0.125rem 0.125rem;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/0.5rem auto no-repeat;
}
.select2-container--bootstrap-5
  .select2--small.select2-selection--single
  .select2-selection__clear:hover,
.select2-container--bootstrap-5
  .select2--small.select2-selection--multiple
  .select2-selection__clear:hover {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/0.5rem auto no-repeat;
}
.select2-container--bootstrap-5
  .select2--small.select2-selection--single
  .select2-search,
.select2-container--bootstrap-5
  .select2--small.select2-selection--single
  .select2-search
  .select2-search__field,
.select2-container--bootstrap-5
  .select2--small.select2-selection--multiple
  .select2-search,
.select2-container--bootstrap-5
  .select2--small.select2-selection--multiple
  .select2-search
  .select2-search__field {
  height: 1.5em;
}
.select2-container--bootstrap-5 .select2--small.select2-dropdown {
  border-radius: 0.25rem;
}
.select2-container--bootstrap-5
  .select2--small.select2-dropdown.select2-dropdown--below {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--bootstrap-5
  .select2--small.select2-dropdown.select2-dropdown--above {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--bootstrap-5
  .select2--small.select2-dropdown
  .select2-search
  .select2-search__field {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}
.select2-container--bootstrap-5
  .select2--small.select2-dropdown
  .select2-results__options
  .select2-results__option {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}
.select2-container--bootstrap-5
  .select2--small.select2-dropdown
  .select2-results__options
  .select2-results__option[role='group']
  .select2-results__group {
  padding: 0.25rem 0.25rem;
}
.select2-container--bootstrap-5
  .select2--small.select2-dropdown
  .select2-results__options
  .select2-results__option[role='group']
  .select2-results__options--nested
  .select2-results__option {
  padding: 0.25rem 0.5rem;
}
.select2-container--bootstrap-5 .select2--small.select2-selection--single {
  padding: 0.25rem 2.25rem 0.25rem 0.5rem;
}
.select2-container--bootstrap-5
  .select2--small.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  padding: 0.35em 0.65em;
  font-size: 0.75rem;
}
.select2-container--bootstrap-5
  .select2--small.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove {
  width: 0.5rem;
  height: 0.5rem;
  padding: 0.125rem 0.125rem;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/0.5rem auto no-repeat;
}
.select2-container--bootstrap-5
  .select2--small.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove:hover {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/0.5rem auto no-repeat;
}
.select2-container--bootstrap-5
  .select2--small.select2-selection--multiple
  .select2-selection__clear {
  right: 0.5rem;
}
.select2-container--bootstrap-5 .select2--large.select2-selection {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.select2-container--bootstrap-5
  .select2--large.select2-selection--single
  .select2-selection__clear,
.select2-container--bootstrap-5
  .select2--large.select2-selection--multiple
  .select2-selection__clear {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem 0.5rem;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1rem auto no-repeat;
}
.select2-container--bootstrap-5
  .select2--large.select2-selection--single
  .select2-selection__clear:hover,
.select2-container--bootstrap-5
  .select2--large.select2-selection--multiple
  .select2-selection__clear:hover {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1rem auto no-repeat;
}
.select2-container--bootstrap-5
  .select2--large.select2-selection--single
  .select2-search,
.select2-container--bootstrap-5
  .select2--large.select2-selection--single
  .select2-search
  .select2-search__field,
.select2-container--bootstrap-5
  .select2--large.select2-selection--multiple
  .select2-search,
.select2-container--bootstrap-5
  .select2--large.select2-selection--multiple
  .select2-search
  .select2-search__field {
  height: 1.5em;
}
.select2-container--bootstrap-5 .select2--large.select2-dropdown {
  border-radius: 0.5rem;
}
.select2-container--bootstrap-5
  .select2--large.select2-dropdown.select2-dropdown--below {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--bootstrap-5
  .select2--large.select2-dropdown.select2-dropdown--above {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--bootstrap-5
  .select2--large.select2-dropdown
  .select2-search
  .select2-search__field {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
.select2-container--bootstrap-5
  .select2--large.select2-dropdown
  .select2-results__options
  .select2-results__option {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
.select2-container--bootstrap-5
  .select2--large.select2-dropdown
  .select2-results__options
  .select2-results__option[role='group']
  .select2-results__group {
  padding: 0.5rem 0.5rem;
}
.select2-container--bootstrap-5
  .select2--large.select2-dropdown
  .select2-results__options
  .select2-results__option[role='group']
  .select2-results__options--nested
  .select2-results__option {
  padding: 0.5rem 1rem;
}
.select2-container--bootstrap-5 .select2--large.select2-selection--single {
  padding: 0.5rem 2.25rem 0.5rem 1rem;
}
.select2-container--bootstrap-5
  .select2--large.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  padding: 0.35em 0.65em;
  font-size: 1rem;
}
.select2-container--bootstrap-5
  .select2--large.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem 0.5rem;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1rem auto no-repeat;
}
.select2-container--bootstrap-5
  .select2--large.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove:hover {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1rem auto no-repeat;
}
.select2-container--bootstrap-5
  .select2--large.select2-selection--multiple
  .select2-selection__clear {
  right: 1rem;
}

.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--single
  .select2-selection__clear,
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__clear {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem 0.5rem;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1rem auto no-repeat;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--single
  .select2-selection__clear:hover,
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__clear:hover {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1rem auto no-repeat;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--single
  .select2-search,
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--single
  .select2-search
  .select2-search__field,
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-search,
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-search
  .select2-search__field {
  height: 1.5em;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown {
  border-radius: 0.5rem;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-dropdown.select2-dropdown--below {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-dropdown.select2-dropdown--above {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-dropdown
  .select2-search
  .select2-search__field {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option[role='group']
  .select2-results__group {
  padding: 0.5rem 0.5rem;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-dropdown
  .select2-results__options
  .select2-results__option[role='group']
  .select2-results__options--nested
  .select2-results__option {
  padding: 0.5rem 1rem;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single {
  padding: 0.5rem 2.25rem 0.5rem 1rem;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  padding: 0.35em 0.65em;
  font-size: 1rem;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem 0.5rem;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1rem auto no-repeat;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove:hover {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1rem auto no-repeat;
}
.form-select-lg
  ~ .select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__clear {
  right: 1rem;
}
