:root {
  --bs-primary-rgb: 85, 2, 67;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 85, 2, 67;
  --bs-white-rgb: 255, 255, 255;
  --bs-body-font-family: Poppins, sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.5rem;
  --bs-link-color: hsl(314, 59%, 58%);
  --bs-link-hover-color: hsl(314, 59%, 48%);
  --bs-code-color: #d63384;
}
