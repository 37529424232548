#sidebar {
  min-width: 270px;
  max-width: 270px;
  background: #0B2911;
  color: #fff;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;

  position: fixed;
  z-index: 1000;
  height: 100vh;
}

#sidebar.active {
  min-width: 0;
  max-width: 0;
  text-align: center;
  visibility: hidden;
  opacity: 0;
}

#sidebar.active ul.components li {
  font-size: 14px;
}

#sidebar.active ul.components li a {
  padding: 10px 0;
}

#sidebar.active ul.components li a span {
  margin-right: 0;
  display: block;
  font-size: 24px;
}

#sidebar.active .logo {
  padding: 10px 0;
}

#sidebar.active .footer {
  display: none;
}

#sidebar .logo {
  display: block;
  padding: 10px 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #FFF;
  font-family: Tahoma, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 96% */
}

@media (prefers-reduced-motion: reduce) {
  #sidebar .logo {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

#sidebar ul.components {
  padding: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  #sidebar ul.components {
    width: 0;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

#sidebar ul li {
  font-size: 16px;
}

#sidebar ul li > ul {
  margin-left: 10px;
}

#sidebar ul li > ul li {
  font-size: 14px;
}

#sidebar ul li a {
  padding: 10px 25px;
  margin: 0 10px 0 10px;
  display: block;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

#sidebar ul li a span {
  margin-right: 15px;
}


#sidebar ul li a:hover {
  color: #fff;
}

#sidebar ul li.active > a {
  background: 0 0;
  color: #fff;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.footer {
  padding: 0 30px;
}

@media (max-width: 991.98px) {
  .footer {
    display: none;
  }
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.btn.btn-primary {
  background: #0B2911;
  border-color: #0B2911;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus {
  background: #0B2911 !important;
  border-color: #0B2911 !important;
}

.font-size-15 {
  font-size: 2rem;
  color: #91F463;
}

.border-8 {
  border-radius: 8px !important;
}

.title-text {
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
  margin-left: 5px;
}

.breadcrumb {
  font-size: 14px;
  margin-bottom: 2rem;
  color: #0c4128 !important;
}

.breadcrumb-item {
  a {
    color: #0c4128 !important;
  }
}

.balance-container {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .header-title {
    display: none;
  }
}

.header-navigation {
  background: #0B2911 !important;
  height: 96px;
  padding: 0 30px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.dashboard-footer-container {
  background: #20A0E1 !important;
  font-family: Tahoma, serif;
  padding: 0 30px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;

  .dashboard-footer-top {
    padding-top: 59px;
    padding-bottom: 49px;

    @media (max-width: 768px) {
      flex-direction: column;
      padding-bottom: 33px;
    }

    .dashboard-footer-links {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        align-items: center;
        flex-direction: column;
      }

      a {
        color: #ffffff;
        font-family: Tahoma, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-right: 63px;

        @media (max-width: 768px) {
          margin-right: 0;
          margin-top: 28px;
          margin-bottom: 28px;
        }
      }

    }

    .dashboard-footer-social {
      i {
        margin: 20px;

        @media (max-width: 768px) {
          padding-top: 28px;
        };
      }
    }
  }

  .dashboard-footer-bottom {
    padding-top: 30px;
    padding-bottom: 59px;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    };

    span {
      color: #ffffff;
      text-align: center;
      font-family: Tahoma, serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.mobile-header {
  background-image: url('../Assests/Icons/circle-shapes.svg');
  background-position: center bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  //padding-bottom: -20px;
}