.faq-accordion {
  padding: 1.063rem;
  margin: 0 0 2.375rem 0;
  border-radius: 15px;
  background: #ffffff;


  @media (max-width: 768px) {
    margin: 0 0 1.563rem 0;
  }

  .faq-accordion-title {
    display: flex;
    justify-content: space-between;

    .hand-pointer {
      cursor: pointer;
    }
  }
}