.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px !important;
  height: 30px !important;
  padding: 6px 0px !important;
  border-radius: 15px !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 1.42857 !important;
}

.success-btn {
  background-color: #198754;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: Arial, sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #157347;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
  &.disabled {
    background-color: rgba(29, 133, 83, 0.85);
    color: #fff;
    cursor: not-allowed;
  }
}

.error-btn {
  background-color: #dc3545;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: Arial, sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #bb2d3b;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
}