.error-page {
  display: flex;
  flex-direction: column;
  background: #FFFFFF !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.image-styles {
  max-width: 100%;
}