.register-container {
  background: #0B2911 !important;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .register-inner {
    border-radius: 28px !important;
    border: 1px solid #81DC59 !important;
    background: #0B2911 !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
    min-width: 717px;
    padding: 0 10px 0 10px;

    @media (max-width: 768px) {
      min-width: 100vw;
      border-radius: 10px;
      padding: 20px;
    }

    .register-subheading {
      color: #FFF;
      text-align: center;
      font-family: Tahoma, sans-serif !important;
      font-size: 15px !important;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

}