.telegram-container {
  .header-container {
    margin-top: 3.5rem;
    margin-bottom: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @media (max-width: 1024px) {
      margin-top: 6.5rem;
      margin-bottom: 0;
      padding-top: 0;
      background-image: none;
    }

	.telegram-body{
		text-align: center !important;
	}
	h3 {
		
	
      padding-top: 1rem;
      padding-left: 16.563rem;
      color: #000000;
      leading-trim: both;
      text-edge: cap;
      font-family: Tahoma, Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;

      @media (max-width: 768px) {
        font-size: 25px;
        padding-top: 2.875rem;
      }
    }
  }

  .telegram-body {
    padding: 7.5rem 16.563rem 13.438rem;
	text-align: center !important;

    @media (min-width: 425px) and (max-width: 1024px) {
      padding: 2rem 1rem 2rem;
    }


    @media (max-width: 425px) {
      padding: 2rem 1rem 2rem;
    }

    .telegram-center-button {
      width: fit-content;
      background: #91F463;
      height: 39px;
      padding-left: 2.375rem;
      padding-right: 2.375rem;
      display: flex;
      align-items: center;
      border-radius: 5px;
      margin-top: 30px;
      margin-bottom: 30px;
      cursor: pointer;

      @media (max-width: 768px) {
        padding-left: 1rem;
        padding-right: 1.5rem;
      }

      span {
        color: #1A732D;
        text-align: center;
        font-family: Tahoma, Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .telegram-icon {
      margin-left: -20px;
      cursor: pointer;
    }

    h3 {
      color: #91F463;
      leading-trim: both;
      text-edge: cap;
      font-family: Tahoma, Arial, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 85.714% */

      @media (max-width: 768px) {
        font-size: 25px;
      }
    }

    p {
      color: #2C2C2C;
      leading-trim: both;
      text-edge: cap;
      font-family: Tahoma, Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      @media (max-width: 768px) {
        font-size: 13px;
      }
    }

    .chat-bubble {
      color: #ffffff;
      font-weight: 400;
      max-width: 255px;
      word-wrap: break-word;
      margin-bottom: 12px;
      position: relative;
      padding: 10px 20px;
      border-radius: 25px;

      &:before, &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 25px;
      }
    }

    .chat-right {
      color: white;
      background: #0062cc;
      align-self: flex-end;
      margin-right: 20px;

      &:before {
        right: -7px;
        width: 20px;
        background-color: #0062cc;
        border-bottom-left-radius: 16px 14px;
      }

      &:after {
        right: -25.3px;
        width: 26px;
        background-color: white;
        border-bottom-left-radius: 10px;
      }
    }

    strong {
      color: #000000;
      leading-trim: both;
      text-edge: cap;
      font-family: Tahoma, Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;



      @media (max-width: 768px) {
        font-size: 13px;
      }
    }
  }
}