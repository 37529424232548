.spinner {
  margin: 20px auto;
}
.spinner svg * {
  fill: #61ceaf;
}

.modal.loaded .spinner {
  display: none;
}
