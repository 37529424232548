.login-registration-popup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70% !important;
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0 0.1rem 0 0.75rem;
  font-family: Arial, sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(221, 223, 225, 0.4);
  border: 1px solid #ced4da;
  border-radius: 0;
  z-index: 10002;
  .email-input {
    border: none !important;
  }
}

@media (min-width: 280px) and (max-width: 1024px) {
  .login-registration-popup {
    width: 100% !important
  }
}

.form-check-input.error {
  border: 1px solid red !important;
  background-color: #ffd9cc;
}

.form-check-label.error {
  color: #ffd9cc;
}

.text-white.text-decoration-underline.ps-1.error {
  color: #ffd9cc !important;
}

.login-registration-popup input {
  width: 100% !important;
  height: 100%;
  padding: 0 0.1rem 0 0.75rem;
  font-family: Arial, sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #232323;
  background-color: transparent;
  border: none !important;
  border-left: 1px solid #fff !important;

}

.login-registration-popup input:focus {
  color: #232323;
  background-color: transparent;
  outline: 0;
}

.login-registration-popup input:-webkit-autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #232323;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #00000000;
}

.login-registration-popup input::-webkit-input-placeholder {
  color: #232323;
}

.login-registration-popup input::-moz-placeholder {
  color: #232323;
}

.login-registration-popup input:-ms-input-placeholder {
  color: #232323;
}

.login-registration-popup input::-ms-input-placeholder {
  color: #232323;
}

.login-registration-popup input::placeholder {
  color: #232323;
}

.login-registration-popup input:focus::-webkit-input-placeholder {
  color: transparent;
}

.login-registration-popup input:focus::-moz-placeholder {
  color: transparent;
}

.login-registration-popup input:focus:-ms-input-placeholder {
  color: transparent;
}

.login-registration-popup input:focus::-ms-input-placeholder {
  color: transparent;
}

.login-registration-popup input:focus::placeholder {
  color: transparent;
}

.login-registration-popup button {
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  color: #FFFFFF;
  background-color: #20A0E1;
  border-radius: 5px;
  margin: 2px 1px 2px 1px;
  box-shadow: none;
  border: 1px solid transparent;

  @media (max-width: 768px) {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }

}

.login-registration-popup button:hover {
  color: #FFFFFF;
  background-color: #20A0E1;
  border-color: #20A0E1;
}

.login-registration-popup button:disabled {
  cursor: not-allowed;
}

.login-register-terms-and-credit {
  margin-top: 1rem;
  margin-bottom: 1.313rem;
  font-family: Tahoma, sans-serif !important;
  font-size: 0.9rem;
  font-weight: 400;
  z-index: 10006;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .popup-terms-conditions-checkbox {
    position: relative;
  }

  input[type=checkbox] {
    width: 20px;
    height: 20px;
  }

  input[type=checkbox]:checked + label::before {
    content: "";
    display: block;
    position: absolute;
    text-align: center;
    height: 20px;
    width: 20px;
    left: 0;
    background-color: #8EE559;
    border-radius: 2px;
    border: 2px solid rgb(150 150 150 / 30%);
  }

  input[type=checkbox]:checked + label::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
    display: block;
    position: absolute;
    left: 3px;
    top: 3px;
  }

  input[type=checkbox]:not(:checked) + label::before {
    content: "";
    display: block;
    position: absolute;
    text-align: center;
    height: 20px;
    width: 20px;
    left: 0;
    background-color: #DDDDDD;
    border-radius: 2px;
    border: 2px solid #F3F3F3;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .login-register-terms-and-conditions {
    color: #232323 !important;
    font-family: Tahoma, sans-serif !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }

  .text-error {
    font-family: Tahoma, sans-serif !important;
    color: #dc3545 !important;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
  }

  .login-register-credit {
    color: #232323 !important;
    font-family: Tahoma, sans-serif !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }

}

.text-error {
  font-family: Tahoma, sans-serif !important;
  color: #dc3545;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}

.registration-link {
  font-family: Tahoma, sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}

.login-registration-title {
  margin-bottom: 20px;
  color: #232323;
  text-align: center;
  font-family: Tahoma, sans-serif !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px; /* 192.857% */
}

.login-registration-subtitle {
  color: #232323;
  text-align: center;
  font-family: Tahoma, sans-serif !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400;
}

.login-registration-question {
  color: #D8D8D8;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
  z-index: 10002;

  span {
    color: #20A0E1 !important;
    font-family: Tahoma, sans-serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

