.banner-bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 15rem;
  z-index: 1;

  //mobile view
  @media (max-width: 1280px) {
    flex-direction: column;
    position: static;
    top: 0;
  }

  .banner-bottom-left {
    width: 50%;
    position: relative;
    z-index: 2;

    @media (max-width: 1280px) {
      width: 100%;
      //reset position relative
      position: sticky;
      z-index: 2;
    }

    img {
      position: absolute;
      top: -35rem;
      z-index: 1;
      width: 100%;
      //transform: rotate(-5deg);

      @media (max-width: 1280px) {
        left: 0;
        scale: 1.1;
        width: 100%;
        //transform: rotate(-10deg);
        position: static;
        object-fit: cover;
      }
      //
      //@media (max-width: 1280px) {
      //  left: 0;
      //  scale: 0.7;
      //  width: 100%;
      //  transform: rotate(-10deg);
      //  position: static;
      //  object-fit: cover;
      //}
    }
  }

  .banner-bottom-right {
    width: 50%;
    z-index: 1;

    @media (max-width: 1280px) {
      width: 100%;
    }
  }
}