.hide-focus input:focus {
  color: #5B636B;
  background-color: transparent;
  outline: 0;
}

.hide-focus input {
  color: #5B636B;
  background-color: transparent;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.step-one-phone-input input:-webkit-autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #5B636B;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #00000000;
}

.terms-conditions-checkbox {
  position: relative;
}

input[type=checkbox] {
  width: 20px;
  height: 20px;
}

input[type=checkbox]:checked + label::before {
  content: "";
  display: block;
  position: absolute;
  text-align: center;
  height: 20px;
  width: 20px;
  left: 0;
  background-color: #8EE559;
  border-radius: 2px;
  border: 2px solid rgb(150 150 150 / 30%);
}

input[type=checkbox]:checked + label::after {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
  display: block;
  position: absolute;
  left: 3px;
  top: 3px;
}

input[type=checkbox]:not(:checked) + label::before {
  content: "";
  display: block;
  position: absolute;
  text-align: center;
  height: 20px;
  width: 20px;
  left: 0;
  background-color: #DDDDDD;
  border-radius: 2px;
  border: 2px solid #F3F3F3;
}

.registration-otp {
  input {
    text-align: center;
    letter-spacing: 1.8rem;
  }
}

.hover-link {
  text-decoration: none; /* No underline initially */
  cursor: pointer; /* Makes it look clickable */
}

.hover-link:hover {  
  text-decoration: underline; /* Underline on hover */
}