.profit-popup-container {
  padding-top: 20px;

  .profit-popup-title {
    h4 {
      color: #000;
      font-family: Tahoma, serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.32px;
    }

    .profit-popup-close-button {
      position: absolute;
      right: 21px;
      top: 18px;
      cursor: pointer;
    }
  }

  .profit-popup-controls {
    padding-left: 40px;
    padding-right: 59px;
    padding-top: 43px;

    .profit-popup-controls-label {
      color: #000;
      font-family: Tahoma, serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.32px;
    }

    .profit-popup-stake-info-text {
      color: rgba(0, 0, 0, 0.50);
      font-family: Tahoma, serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.32px;
    }

    .profit-popup-controls-dropdown {
      //make the selected bold


    }
  }

  .profit-popup-footer {
    padding: 0 47px 16px 40px;

    p {
      color: rgba(0, 0, 0, 0.80);
      font-family: Tahoma, serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 138.462% */
      letter-spacing: 0.32px;
    }

    button {
      padding: 0.4rem 1.125rem;
      color: #1A732D;
      background-color: #91F463;
      border-radius: 5px;
      margin: 2px 1px 2px 1px;
      box-shadow: none;
      border: 1px solid transparent;

      @media (max-width: 768px) {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
      }

    }

    button:hover {
      color: #1A732D;
      background-color: #57f110;
      border-color: #91F463;
    }

    button:disabled {
      cursor: not-allowed;
    }
  }
}