.cookie-consent-main {
  position: fixed !important;
  bottom: 0px !important;
  width: 30vw !important;
  left: 35vw !important;
  right: 35vw !important;
  z-index: 999999 !important;
  padding: 10px 20px !important;
  color: #874949 !important;
  font-size: 14px !important;
  font-family: Arial, sans-serif !important;
  min-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-radius: 13px;
  background: #FFF !important;
  box-shadow: 0px 4px 200px 0px rgba(0, 0, 0, 0.25) !important;
  margin-bottom: 20px !important;

  @media (max-width: 1250px) {
    width: 90vw !important;
    left: 5vw !important;
    right: 5vw !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;

  }

  .cookie-container {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    flex-direction: column;
    margin-bottom: -10px !important;

    h3 {
      margin-top: 0.75rem;
      color: #1D262E;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: "Inter", Tahoma, Arial, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 111.111% */
    }

    span {
      color: #1D262E;
      leading-trim: both;
      text-edge: cap;
      font-family: "Inter", Tahoma, Arial, sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      @media (min-width: 325px) {
        width: inherit !important;
        text-wrap: normal !important;
      }
    }
  }

  .cookie-consent-accept-button {
    background: #20A0E1 !important;
    border-radius: 5px !important;
    margin-left: 20px !important;
    padding: 0.71rem 3.6rem !important;
    cursor: pointer !important;
    color: #FFFFFF !important;
    text-align: center;
    font-family: "Inter", Tahoma, Arial, sans-serif;
    font-size: 15px;
    font-style: normal;
    letter-spacing: 0.5px;
  }

  .cookie-consent-decline-button {
    background: none !important;
    border: none !important;
    cursor: pointer !important;
    color: #20A0E1 !important;
    text-align: center;
    font-family: "Inter", Tahoma, Arial, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;

  }

  .cookie-consent-button-wrapper {
    display: flex !important;
    align-items: center !important;
    margin: 0 auto !important;
  }

  .cookie-consent-details-link {
    text-decoration: underline !important;
    color: rgb(255, 255, 255) !important;
    font-weight: bold !important;
  }
}

