.ribbon-container {
  border-radius: 0 0 8px 8px;
  background: #91F463;
  width: 35%;
  height: 4.5rem;
  margin: 0 auto;
  border-bottom-color: #092510;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  cursor: pointer;

  @media (max-width: 1056px) {
    width: 100%;
    border-radius: 0;
  }

  .content-text {
    color: #1A732D;
    leading-trim: both;
    text-edge: cap;
    font-family: Tahoma, Arial, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}