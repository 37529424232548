th {
  background: #FFFFFF !important;
  color: #82888A !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.table-row-light {
  border: 1px solid #ECECEC;
  background: #FFF;
}

.table-row-dark {
  border: 1px solid #ECECEC;
  background: #FAFAFA;
}

.text-orange {
  color: #FFA500;
  font-size: 14px;
}
